<template lang="">
  <Card>
    <template #header> Kullanıcı Bilgileri </template>
    <div class="container">
      <div class="row">
        <div class="card-sub-header-2">
          <h4>Kullanıcı Bilgileri</h4>
        </div>

        <div class="col-md-6 col-12">
          <div class="form-group">
            <label for="tckn">T.C. Kimlik No</label>
            <input
              v-model="tc_kimlik_no"
              type="text"
              class="form-control"
              id="tckn"
            />
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label for="email">E-Posta Adresi</label>
            <input
              v-model="eposta"
              type="text"
              class="form-control"
              id="email"
            />
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label for="adi">Adı</label>
            <input v-model="adi" type="text" class="form-control" id="adi" />
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label for="soyadi">Soyadı</label>
            <input
              v-model="soyadi"
              type="text"
              class="form-control"
              id="soyadi"
            />
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="user_group">Birimi</label>
            <select class="form-control" v-model="user_group" id="user_group">
              <option value="" selected>Seçiniz</option>
              <option value="software">Yazılım Geliştirme</option>
              <option value="control">Kontrol</option>
              <option value="representative">Müşteri Temsilcisi</option>
              <option value="expert">Saha Operasyon Uzmanı</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="card-sub-header-2">
          <h4>Kişi Bilgileri</h4>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label for="gorevi">Görevi</label>
            <input
              v-model="gorevi"
              type="text"
              class="form-control"
              id="gorevi"
            />
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label for="meslek">Mesleği</label>
            <input
              v-model="meslek"
              type="text"
              class="form-control"
              id="meslek"
            />
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label for="gorevi">Açıklama</label>
            <textarea
              id="aciklama"
              v-model="aciklama"
              class="form-control"
              rows="1"
            ></textarea>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label for="gorevi">Doğum Tarihi</label>
                <input type="date" v-model="birth_date" class="form-control" >
          </div>
        </div>

      </div>
      <div class="row">
        <div class="card-sub-header-2">
          <h4>İletişim Bilgileri</h4>
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <div class="form-group">
            <label for="telev">Telefonu (ev)</label>
            <masked-input
              class="form-control form-control-lg"
              @input="telefon_ev = arguments[1]"
              v-model="telefon_ev"
              mask="(111) 111-11-11"
              id="telev"
              placeholder="(___) ___-____"
            />
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <div class="form-group">
            <label for="telis">Telefonu (iş)</label>
            <masked-input
              class="form-control form-control-lg"
              @input="telefon_is = arguments[1]"
              v-model="telefon_is"
              mask="(111) 111-11-11"
              id="telis"
              placeholder="(___) ___-____"
            />
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <div class="form-group">
            <label for="telgsm1">GSM (1)</label>
            <masked-input
              class="form-control form-control-lg"
              @input="telefonu_gsm1 = arguments[1]"
              v-model="telefonu_gsm1"
              mask="(111) 111-11-11"
              id="telgsm1"
              placeholder="(___) ___-____"
            />
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <div class="form-group">
            <label for="telgsm2">GSM (2)</label>
            <masked-input
              class="form-control form-control-lg"
              @input="telefonu_gsm2 = arguments[1]"
              v-model="telefonu_gsm2"
              mask="(111) 111-11-11"
              id="telgsm2"
              placeholder="(___) ___-____"
            />
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <div class="form-group">
            <label for="telgsm2">GSM Kısa Kod</label>
            <input type="text" class="form-control" v-model="gsm_kisa_kod">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="card-sub-header-2">
          <h4>Şifre İşlemleri</h4>
        </div>
       
        <div class="col-lg-6 col-12">
            <div class="form-group">
                <label for="password">Şifre</label>
                <input type="password" v-model="password" class="form-control">
            </div>
        </div>
        <div class="col-lg-6 col-12">
            <div class="form-group">
                <label for="password">Şifre Tekrar</label>
                <input type="password" v-model="password_repeat" class="form-control">
            </div>
        </div>
        <div class="col-12 ">
            <p v-if="!macth_passwords && password.length > 0 && password_repeat.length >0" class="text-danger">Şifreler Aynı Olmalıdır...</p>

        </div>
        <div class="d-flex justify-content-center w-100">
            <button class="btn btn-primary" type="button"  @click="updateUser">Kaydet</button>
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import MaskedInput from "vue-masked-input";
import ApiService from '@/core/services/api.service';
import moment from "moment";
import { mapGetters } from "vuex";
import {GET_MY_USER} from "@/core/services/store/auth.module.js"
moment.locale("tr")
import Swal from "sweetalert2"
export default {
  props: {
    user: Object,
  },
  data() {
    return {
      tc_kimlik_no: "",
      eposta: "",
      adi: "",
      soyadi: "",
      user_group: "",
      gorevi: "",
      meslek: "",
      aciklama: "",
      telefon_ev: "",
      telefon_is: "",
      telefonu_gsm1: "",
      telefonu_gsm2: "",
      gsm_kisa_kod: "",
      password: "",
      password_repeat: "",
      birth_date:"",
      macth_passwords:false,

    };
  },
  mounted(){
    this.setUserData()
  },
  components: {
    MaskedInput,
  },
  watch:{
    password(){
        this.controlPasswords()
    },
    password_repeat(){
        this.controlPasswords()
    },
    birth_date(){
    }
    
  },

  methods: {
    setUserData(){
      this.tc_kimlik_no = this.user.tc_kimlik_no,
      this.adi = this.user.adi,
      this.soyadi = this.user.soyadi,
      this.eposta = this.user.eposta,
      this.telefonu_gsm1 = this.user.telefonu_gsm1,
      this.birth_date = moment(this.user.birth_date,"DD.MM.YYYY").format("YYYY-MM-DD")
      this.gorevi = this.user.gorevi
      this.user_group = this.user.user_group
      this.meslek = this.user.meslek
      this.aciklama = this.user.aciklama
      this.telefon_ev = this.user.telefon_ev
      this.telefon_is = this.user.telefon_is
      this.telefonu_gsm2 = this.user.telefonu_gsm2
      this.gsm_kisa_kod = this.user.gsm_kisa_kod
    },
    controlPasswords(){
        if(this.password.length >0 && this.password_repeat.length >0  ){
          if(this.password !== this.password_repeat){
            this.macth_passwords = false
          }
          else{
            this.macth_passwords = true
          }
        }
    },
    updateUser(){
      let data = {
        user_token : this.myUser,
        tc_kimlik_no: this.tc_kimlik_no,
        target_user_id: this.user._id.$oid,
        eposta: this.eposta,
        adi: this.adi,
        soyadi: this.soyadi,
        user_group: this.user_group,
        gorevi: this.gorevi,
        meslek: this.meslek,
        aciklama: this.aciklama,
        telefon_ev: this.telefon_ev,
        telefon_is: this.telefon_is,
        telefonu_gsm1: this.telefonu_gsm1,
        telefonu_gsm2: this.telefonu_gsm2,
        gsm_kisa_kod: this.gsm_kisa_kod,
        password: this.password,
        birth_date:moment(this.birth_date).format("DD.MM.YYYY")
      }
      const formDataSend = new FormData()
      for (const key in data) {
        formDataSend.append(key, data[key]);
      }

        ApiService.put("user/user",formDataSend).then((response)=>{
          if(response.data.status.code !== 400){
            if(response.data.response.user_data._id.$oid === this.currentUser.user_id){
              this.$store.dispatch(GET_MY_USER,response.data.response.user_data.user_token)
               
            }
            Swal.fire("Başarılı", "Kullanıcı Başaryıla Güncellendi.",'success').then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  setTimeout(()=>{
                    window.location.reload()
                  },1000) 
                } 
              })
              ;
          
           
          }else{
            this.$generateNotification(this, 'error', "Kullanıcı Düzenlenirken Bir Hata ile Karşılaşıldı.");

          }
        }).catch(response =>{
          this.$generateNotification(this, 'error', "Hata");
        })
      
    }

  },
  computed:{
    ...mapGetters(["myUser","currentUser"])
  }
};
</script>
<style lang="scss" scoped>
.card-sub-header-2 {
  padding-bottom: 0.5rem;
  margin: 0 1rem;
  width: 100%;
  border-bottom: 2px solid $dveb-primary;
  margin-bottom: 2rem;
}
</style>